import React from "react";
import {
  Stack,
  Heading,
  Center,
  Text,
  Avatar,
  Button,
  Link,
} from "@chakra-ui/react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";

import { useSpring, animated } from "@react-spring/web";
import photo from "../assets/suited-up.jpg";

export const Biography = () => {
  const springs = useSpring({
    from: { y: -1000 },
    to: { y: 0 },
  });
  return (
    <Stack>
      <animated.div
        style={{
          ...springs,
        }}
      >
        <Heading size="l" p={2}>
          About us
        </Heading>
        <Heading size="md">CEO</Heading>
        <Center pt={2}>
          <Avatar size="2xl" name="Blaz Bagic" src={photo} />
        </Center>
        <Stack>
          <Heading size="md">Blaž AKA "The Debugginator"</Heading>

          <Center>
            <Link href="https://www.github.com/debugginator" isExternal>
              <Button
                leftIcon={<FaGithub />}
                variant="link"
                colorScheme="black"
              />
            </Link>
            <Link href="https://www.linkedin.com/in/debugginator" isExternal>
              <Button
                leftIcon={<FaLinkedin />}
                variant="link"
                colorScheme="black"
              />
            </Link>
            <Link href="mailto:blaz@debugginator.com" isExternal>
              <Button
                leftIcon={<MdAlternateEmail />}
                colorScheme="black"
                variant="link"
              />
            </Link>
          </Center>
          <Text py="2" fontSize="sm">
            Seasoned engineer with a wealth of experience in the fintech sector,
            <br />
            specializing in the development of mobile and web applications.
          </Text>
        </Stack>
      </animated.div>
    </Stack>
  );
};
