import React, { useEffect, useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";

import "./App.css";
import { Biography } from "./components/Biography";
import { HomePage } from "./components/HomePage";

function App() {
  const [isBio, setIsBio] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setIsBio(true), 2000);
  }, [setIsBio]);
  return (
    <ChakraProvider>
      <div className="App">
        <header className="App-header">
          {isBio ? <Biography /> : <HomePage />}
        </header>
      </div>
    </ChakraProvider>
  );
}

export default App;
