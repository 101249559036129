import React from "react";
import { Progress, Stack } from "@chakra-ui/react";

import logo from "../assets/logo.svg";

export const HomePage = () => {
  return (
    <Stack>
      <img src={logo} className="App-logo" alt="logo" />
      <p>Debugginator LLC</p>
      <p style={{ fontSize: 14 }}>the Mobile development company</p>
      <Progress
        mt={10}
        hasStripe
        height={1}
        minW={300}
        background={"#282c34"}
        isIndeterminate
      />
    </Stack>
  );
};
